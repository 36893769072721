.welcome {
  color: white;
  height: calc(100vh);
  background: url("../../media/cnext-fondo-mobile-9sep24.jpg");
  background-position: top center;
  background-size: cover;
 // padding: 50px 40px 0;
  .logos {
    display: flex;
    justify-content: space-between;
  }
  .logo {
    &__c-next {
      position:fixed;
      top: 0px;
      left: 0px;
      width: 375px;
      margin: 20px 0px;
    }
    &__cosentino {
      display: none;
      width: 200px;
      display: none;

    }
    &__welcome {
      width: 490px;
      display: none;
    }
    &__welcomemobile {
      width: 200px;
      margin-top: 25px;
      margin-left: 22px;
      display: block;
    }
  }
  .container {
    padding-top: 126px;
  }
  button {
    text-transform: uppercase;
    width: 240px;
    padding: 12px 0;
    font-family: "Neue Montreal Medium";
    color: #000;
    background-color: #fff;
    border: none;
    cursor: pointer;
    margin-top: 30px;
    margin-left: 40px;
  }

  .disabled {
    cursor: not-allowed;
    color: #9ca4a5;
    background-color: rgb(100, 100, 100);
  }
  .countdown {
    display: block;
    font-family: "Diagramm Normal";
   // margin: 0 auto;
  //  text-align: center;
    width: 300px;
    padding: 0px 40px;
    margin-top: 0px;
 //   display: flex;
  //  flex-direction: column;
  //  align-items: center;
    .numbers {
      display: inline;
     // top: 70px;
      position: relative;
      color: white;
      font-size: 42px;
    }
    .triangle {
      width: 262px;
    }
    .info_text {
      font-size: 15px;
    }
  }
}

@media (min-width: 768px) {
  .welcome {
    background: url("../../media/cnext-fondo-desktop-9sep24.jpg");
    background-position: center;
    background-size: cover;
    .logo {
      &__c-next {
        position:fixed;
        margin: 35px 0px;
        top: 0px;
        left: 0px;
        width: 532px;
      }
      &__cosentino {
        display: initial;
      }
      &__welcome{
        display: block;
      }
      &__welcomemobile {
        display: none;
      }
    }
    .container {
      display: flex;
      flex-direction: row;
     //justify-content: space-evenly;
      padding: 100px 100px;
      padding-top: 240px;
    }
    .countdown {
      //margin: initial;
      display: block;
      margin-top: 5px;
      padding: 0px 22px;

      width: 500px;
      .numbers {
       // position: absolute;
        font-size: 62px;
      //  top: 210px;
      }
      .triangle {
        width: 430px;
      }
      .info_text {
        font-size: 20px;
      }
    }
    button {
      width: 170px;
      margin-left: 24px;
    }
  }

}
